import React from "react";
import "./AcercaDe.css";

export default function AcercaDe() {
  return (
  <div className="container-acercaDe">
    <div className="container-video-acercaDe">
    <iframe className="video-iFrame" src="https://www.youtube.com/embed/kyiL-hjVLnM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <p className="descripcion-video">Premios ACE noviembre 2022</p>
  </div>
    <p className="parrafos-acercaDe">La Biblioteca Café inició sus actividades en septiembre de 2000. El objetivo de su creadora, Edith Margulis, era - y es - abrir un espacio con cabida a espectáculos musicales, teatrales, y todo tipo de actividades culturales, incluyendo dentro de lo cultural una propuesta gastronómica, acompañada de una ambientación armónica y atención personal.</p>
    <p className="parrafos-acercaDe">A lo largo de 22 años La Biblioteca Café ha propuesto una programación musical que abarca los más variados estilos, desde los tradicionales como tango, jazz, pop, folclore, cancionero latinoamericano, bossa y bolero, hasta otros menos difundidos como pueden ser canciones francesas, italianas, gallegas, judías, fado portugués, celta, flamenco y otras, además de conciertos de música académica, antigua, ópera, etc. También obras de teatro y teatro musical, narración oral, talleres de canto y de diversas disciplinas, exposiciones de plástica, presentaciones de libros, y estamos abiertos a nuevas expresiones. Los espectáculos están acompañados de cena, en parte de los casos, temáticas.</p>
    <p className="parrafos-acercaDe">Por nuestros escenarios han pasado artistas consagrados como Jorge Navarro, Ligia Piro, Ana Padovani, Marikena Monti, Horacio Molina, Lidia Borda, Grupo Vocal Argentino, Quinteto Mayor, Victor Torres, Leo Masliah, Ariel Ardit, Gipsy Bonafina, Leonardo Pastore, Patricia Malanca, Blas Rivera, María Volonté, Fénix Jazz Band, y cientos de artistas al año, además de Edith Margulis, su dueña y programadora artística, con 15 espectáculos propios entre 2000 y 2022, y 2 en proyecto para el 2023.</p>
    <p className="parrafos-acercaDe">Edith Margulis recibió distinciones por varios de sus proyectos artísticos y por su gestión en La Biblioteca Café. Los eventos que se realizan tienen una amplia repercusión en la crítica especializada, no sólo del Espectáculo si no también de Gastronomía.</p>
    <p className="parrafos-acercaDe">La Biblioteca Café está instalada en el ámbito de la Asociación Biblioteca de Mujeres (ubicada en un edificio histórico, el Palacio Guerrico), de la cual recibe un amplio apoyo para su gestión.</p>
    <p className="parrafos-acercaDe">A partir de abril de 2020, la Biblioteca Café ha mantenido contacto con el público, realizando eventos virtuales y también, cuando las restricciones no permitieron actividades en el interior de la sala, inaugurando funciones en el espacio público que tenemos habilitado, con gran repercusión. Por esta actividad, La Biblioteca Café recibió una Mención Especial de Reconocimiento de la Asociación de Cronistas del Espectáculo ACE, el 27 de marzo de 2022:</p>
    <div className="container-video-acercaDe">
    <iframe className="video-iFrame" src="https://www.youtube.com/embed/q4Yfda3UI7E?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <p className="descripcion-video">Premios ACE marzo 2022</p>
    </div>
    <p className="parrafos-acercaDe">Actualmente La Biblioteca Café tiene una programación que cubre los 7 días de la semana, con artistas de reconocida trayectoria, junto a nuevos intérpretes. Entre los espectáculos consagrados actualmente en cartel están, entre muchos más: La Cena de Leonardo Da Vinci (Premio ACE 2007/2008, con 7 temporadas entre el 2008 y 2015, y repuesta en 2022 con el auspicio de Mecenazgo Cultural), Entre dos Guerras un Tango (Nominada Premio ACE 2017/2018), Almalusa (8 años consecutivos), Parlami D’Amore (5 años consecutivos), y -desde hace 15 años- los Lunes de Jazz con el trío de Angel Sucheras.</p>
    <p className="parrafos-acercaDe">El 9 de noviembre del 2022, La Biblioteca Café recibió una distinción especial de parte de la Asociación de Cronistas del Espectáculo ACE, por los 22 años de permanencia en el ámbito de la cultura y su aporte a la misma</p>
    
  </div>)
  }
